<script type="text/javascript">
let Base64 = require('js-base64').Base64
function reqEncrypt(data) {
  console.log("加密源：", data);
  const data_str = JSON.stringify(data);
  const res = Base64.encode(Base64.encode(data_str));
  console.log('加密结果：', res);
  return res
}

function resDecrypt(data_str) {
  console.log("解密源：", data_str);
  const res = Base64.decode(Base64.decode(data_str));
  const data = JSON.parse(res);
  console.log('解密结果：', data);
  return data
}

function getResData(res) {
  console.log('接口返回数据:', res)
  return resDecrypt(res.data.data)
}

export default {
  reqEncrypt,
  resDecrypt,
  getResData,
}
</script>